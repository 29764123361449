import { s7ImagePath } from '~/global/global.constants';

import type { BestSellerLinkItem, BestSellerLinkList, BestSellersData } from '~/components/best-sellers/BestSellers.types';

export enum BestSellerRoute {
	FordChairs = '/catalog/living/ford-collection',
	HudsonStorage = '/catalog/bedroom/hudson-collection',
	LinearStorage = '/catalog/storage-and-entryway/linear-collection',
	MetroSofasSectionals = '/catalog/living/metro-sofas-and-sectionals',
	ParsonsTables = '/catalog/dining-and-kitchen/parsons-table-collection',
}

export enum BestSellersTitle {
	CustomerFavorites = 'Customer Favorites',
}

export const bestSellerLinks: Record<BestSellerRoute, BestSellerLinkItem> = {
	[BestSellerRoute.FordChairs]: {
		caption: 'Ford Chairs',
		href: BestSellerRoute.FordChairs,
		imageHeight: 360,
		imageSrc: `${s7ImagePath}/custFaves_04`,
		imageWidth: 305,
	},
	[BestSellerRoute.HudsonStorage]: {
		caption: 'Hudson Storage',
		href: BestSellerRoute.HudsonStorage,
		imageHeight: 360,
		imageSrc: `${s7ImagePath}/custFaves_03`,
		imageWidth: 305,
	},
	[BestSellerRoute.LinearStorage]: {
		caption: 'Linear Storage',
		href: BestSellerRoute.LinearStorage,
		imageHeight: 360,
		imageSrc: `${s7ImagePath}/custFaves_05`,
		imageWidth: 305,
	},
	[BestSellerRoute.MetroSofasSectionals]: {
		caption: 'Metro Sofas & Sectionals',
		href: BestSellerRoute.MetroSofasSectionals,
		imageHeight: 360,
		imageSrc: `${s7ImagePath}/custFaves_01`,
		imageWidth: 305,
	},
	[BestSellerRoute.ParsonsTables]: {
		caption: 'Parsons Tables',
		href: BestSellerRoute.ParsonsTables,
		imageHeight: 360,
		imageSrc: `${s7ImagePath}/custFaves_02`,
		imageWidth: 305,
	},
};

export const customerFavoritesLinks: BestSellerLinkList = [
	bestSellerLinks[BestSellerRoute.MetroSofasSectionals],
	bestSellerLinks[BestSellerRoute.ParsonsTables],
	bestSellerLinks[BestSellerRoute.HudsonStorage],
	bestSellerLinks[BestSellerRoute.FordChairs],
	bestSellerLinks[BestSellerRoute.LinearStorage],
];

export const customerFavoritesData: BestSellersData = {
	headerLevel: 2,
	links: customerFavoritesLinks,
	title: BestSellersTitle.CustomerFavorites,
};
