import axios from 'axios';

import {
	IVideoMilestoneEventData, IVideoPlayEventData, IWistiaMedia, IWistiaOEmbedResponse, VideoMilestoneEvent, VideoPercentWatched,
} from '~/components/wistia-player/Types/WistiaPlayer.type';
import { LAUNCH_PLACEHOLDER_TEXT } from '~/util/launchUtils';
import { sanitize } from '~/util/sanitizeString';
import { createWistiaPlayerStore, WistiaPlayerStore } from '~/components/wistia-player/Stores/WistiaPlayer.store';
import { ShowcaseVariant, ThumbnailSize, thumbnailSizeOptions } from '~/components/wistia-player/Types/constants';
import { CareersShowcaseProps, StandardShowcaseProps, WistiaShowcaseProps } from '~/components/wistia-player/Components/WistiaShowcase';
import { LinkEventStore } from '~/tracking/link-event/Stores/LinkEvent.store';
import { MagicModalStore } from '~/components/magic-modal/Stores/MagicModal.store';

export const wistiaRequest = async (videoId: string): Promise<IWistiaOEmbedResponse | undefined> => {
	const url = `https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/${videoId}?embedType=async`;
	const axiosInstance = axios.create({ withCredentials: false });

	delete axiosInstance.defaults.headers.common['X-Requested-With'];
	delete axiosInstance.defaults.headers.common['X-Forwarded-For'];
	delete axiosInstance.defaults.headers.common['True-Client-IP'];
	delete axiosInstance.defaults.headers.common['X-Bypass-Ratelimiting'];

	try {
		const response = await axiosInstance.request({ url });

		return { data: response.data };
	} catch (error) {
		console.error(error);

		return undefined;
	}
};

export const formatSecondsToMinutesAndSeconds = (seconds: number) => {
	const totalSeconds = Math.round(seconds);
	const minutes = Math.floor(totalSeconds / 60);
	const remainingSeconds = totalSeconds % 60;
	const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

	return `${minutes}:${formattedSeconds}`;
};

export const getThumbnail = (url: string, size: ThumbnailSize = ThumbnailSize.Default) => {
	if (!url) {
		return '';
	}

	const thumbnail = new URL(url);

	thumbnail.searchParams.set('image_crop_resized', thumbnailSizeOptions[size]);

	return thumbnail.toString();
};

export const buildMilestoneEventTrackingData = (
	pageID: string,
	compName: string,
	percentWatched: VideoPercentWatched,
): IVideoMilestoneEventData => {
	return {
		event: `video${percentWatched}`,
		content: {
			compName: sanitize(compName),
			compPosition: LAUNCH_PLACEHOLDER_TEXT,
			compType: 'wistia player',
			dURL: LAUNCH_PLACEHOLDER_TEXT,
			pageID,
			name: `video viewed ${percentWatched}`,
			type: 'video',
		},
	};
};

export const buildPlayEventTrackingData = (
	name: string,
	videoId: string,
): IVideoPlayEventData => {
	return {
		componentType: 'wistia player',
		id: videoId,
		name,
		url: `https://fast.wistia.net/embed/iframe/${videoId}`,
	};
};

export const getMilestoneEventTrackingDataOrNull = (
	pageID: string,
	compName: string,
	percent: number,
	lastPercent: number,
	milestonesAlreadyReached: VideoMilestoneEvent[],
	isEngage: boolean,
): IVideoMilestoneEventData | null => {
	if (isEngage) {
		return null;
	}

	if (percent >= 0.25 && percent < 0.50 && lastPercent < 0.25 && !milestonesAlreadyReached.includes('video25')) {
		return buildMilestoneEventTrackingData(pageID, compName, 25);
	}

	if (percent >= 0.50 && percent < 0.75 && lastPercent < 0.50 && lastPercent > 0.25 && !milestonesAlreadyReached.includes('video50')) {
		return buildMilestoneEventTrackingData(pageID, compName, 50);
	}

	if (percent >= 0.75 && percent < 1 && lastPercent < 0.75 && lastPercent > 0.5 && !milestonesAlreadyReached.includes('video75')) {
		return buildMilestoneEventTrackingData(pageID, compName, 75);
	}

	if (percent === 1 && !milestonesAlreadyReached.includes('video100')) {
		return buildMilestoneEventTrackingData(pageID, compName, 100);
	}

	return null;
};

export const calculateVideoPercentWatched = (secondsWatched: number, duration: number): number => {
	if (duration === 0) {
		return 0;
	}

	return secondsWatched / Math.floor(duration);
};

export const hasTrackedMilestoneEvent = (milestoneEvent: VideoMilestoneEvent, compName: string, eventDataLayer: any[] = []): boolean => {
	return eventDataLayer.some(event => event?.event === milestoneEvent && event?.content?.compName === sanitize(compName));
};

export const hasTrackedPlayEvent = (compName: string, eventDataLayer: any[] = []): boolean => {
	return eventDataLayer.some(event => event?.content?.name === 'play' && event?.content?.compName === sanitize(compName));
};

export const buildPlayers = async (
	media: IWistiaMedia[],
	linkEventStore: LinkEventStore | null = null,
	magicModal: MagicModalStore | null = null,
	isMediaQueryMd: boolean = true,
	showcaseVariant: ShowcaseVariant = ShowcaseVariant.Standard,
): Promise<WistiaPlayerStore[] | undefined> => {
	try {
		const buildPlayer = async (video: IWistiaMedia) => {
			const store = createWistiaPlayerStore(video.hashedId, linkEventStore, magicModal, isMediaQueryMd, showcaseVariant);

			await store.getVideoData(ThumbnailSize.Showcase);

			return store;
		};

		return await Promise.all(media.map(buildPlayer));
	} catch (error) {
		console.error(error);

		return undefined;
	}
};

export const isCareersShowcase = (props: WistiaShowcaseProps): props is CareersShowcaseProps => (
	props.variant === ShowcaseVariant.Careers
);

export const isStandardShowcase = (props: WistiaShowcaseProps): props is StandardShowcaseProps => (
	!props.variant || props.variant === ShowcaseVariant.Standard
);
