import { customerFavoritesData } from '~/components/best-sellers/BestSellers.constants';
import { modernStyleGalleryData } from '~/components/customer-photos/CustomerPhotos.constants';
import { recentlyViewedData } from '~/components/product-recommendations/ProductRecommendations.constants';
import { officeLivingStorageLinks } from '~/components/core-categories/CoreCategories.constants';
import { CONFIDENCEMSG_ID } from '~/homepage/Components/ConfidenceMsgs/ConfidenceMsg.type';
import { FEATCATS_ID } from '~/components/featured-categories/Components/HomepageFeatCats.type';
import { HERO_ID } from '~/components/hero/Components/HomepageHeroes.type';
import { IHomepageSettings } from '~/homepage/HomepageSettings.type';
import { SHOPPING_CAROUSELS } from '~/components/shopping-carousel/ShoppingCarousel.types';

export const HomepageSettings: IHomepageSettings = {
	hero: HERO_ID.BAYLOR_VIDEO,
	confidenceMsg: CONFIDENCEMSG_ID.DonationsMessage,
	featCats: FEATCATS_ID.DINING_BEDROOM,
	coreCats: officeLivingStorageLinks,
	bestSellers: customerFavoritesData,
	productRecommendations: recentlyViewedData,
	customerPhotos: modernStyleGalleryData,
	confidenceMsg2: CONFIDENCEMSG_ID.GoodFurnitureVideoMessage,
	newCustomerBanner: CONFIDENCEMSG_ID.CraftspeopleMessage,
	shoppingCarousel: SHOPPING_CAROUSELS.NOV_NEW_24,
};
