import React from 'react';

import { Hero } from '~/components/hero/Hero';
import { HORIZONTAL_ALIGNMENT, IHeroWithBackgroundImage, VERTICAL_ALIGNMENT } from '~/components/hero/Hero.type';

export const NEW_ARRIVALS = () => {
	const heroArgs: IHeroWithBackgroundImage = {
		buttonText: 'Shop New Arrivals',
		buttonLink: '/catalog/living/new-arrivals',
		headline: 'Fresh Ideas',
		headlineClass: '',
		headlineButtonAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		headlineButtonContainerHorizontalAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		imgHorizontalAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		imgSrc: 'homepageHero_freshFinds_1920',
		imgHeight: 500,
		imgWidth: 500,
		headlineButtonContainerVerticalAlignment: VERTICAL_ALIGNMENT.TOP,
		nudgeHorizontal: 0,
		nudgeVertical: 15,
		imgNudgeHorizontalXS: -315,
	};

	return (
		<Hero { ...heroArgs } />
	);
};
