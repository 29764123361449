import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from '~/components/full-width-container/FullWidthContainer.module.scss';

interface IProps {
	className?: string;
	containerDataQa?: string;
	contentClassName?: string;
	contentDataQa?: string;
	isFullWidth?: boolean;
}

export const FullWidthContainer = (props: PropsWithChildren<IProps>) => {
	const {
		className = '',
		containerDataQa = '',
		contentClassName = '',
		contentDataQa = '',
		isFullWidth = true
	} = props;

	return (
		<div
			className={classNames({
				[className]: className,
				[styles.widenedContainer]: isFullWidth,
			})}
			data-qa={containerDataQa}
		>
			<div
				className={classNames({
					[contentClassName]: contentClassName,
					[styles.widenedContent]: true,
				})}
				data-qa={contentDataQa}
			>
				{props.children}
			</div>
		</div>
	);
};
