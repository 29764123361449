import React, { MouseEventHandler } from 'react';

import { MagicHeaderTag } from '~/components/magic-header';

export const ClearancePromo = ({ clickHandler }: { clickHandler?: MouseEventHandler }) => {
	return (
		<div className="tw-bg-red tw-flex tw-justify-center tw-pb-10 tw-pt-3 tw-px-6 md:tw-px-8 md:pb-12">
			<div className="tw-flex tw-flex-col tw-items-center">
				<div className="tw-border-b tw-border-white tw-mb-4 tw-text-center tw-w-full">
					<MagicHeaderTag headerLevel={2} className="tw-text-[60px] md:tw-text-[80px] tw-font-semibold tw-text-white tw-mb-1">clearance</MagicHeaderTag>
				</div>
				<span className="tw-block tw-body-2 tw-font-semibold tw-text-center tw-text-white tw-mb-4 md:tw-mb-6 md:tw-px-6 ">100s of new items added! Get 20-50% off discontinued furniture and decor.</span>
				<a className="HollowButton HollowButton--transparent HollowButton--white tw-text-lg" href="/clearance" onClick={clickHandler}>Shop Clearance</a>
			</div>
		</div>
	);
};
