import React from 'react';

import { withPagePromise } from '~/global/app-config/withPagePromise';
import { HomepageContextProvider } from '~/homepage/Contexts/Homepage.context';
import { HomepageLayout } from '~/homepage/Components/HomepageLayout.pages';
import { PrimaryLayout } from '~/layout/Components/PrimaryLayout';
import { PERSONALIZATION_SCOPE } from '~/personalization/Personalization.types';

export interface HomepageProps {
	globalDynamicData: any,
	pageType: string,
}

const Homepage = (props: HomepageProps) => {
	return (
		<PrimaryLayout fullWidth>
			<HomepageContextProvider props={props}>
				<HomepageLayout />
			</HomepageContextProvider>
		</PrimaryLayout>
	);
};

export default Homepage;

export const getServerSideProps = withPagePromise(async (pageProps) => {
	return {
		props: {
			globalDynamicData: pageProps.globalDynamicData,
			pageType: 'main',
			personalizationScope: [PERSONALIZATION_SCOPE.FLEX_1, PERSONALIZATION_SCOPE.FLEX_2]
		}
	};
});
