import React from 'react';

import styles from '~/homepage/Components/HomepageHero.module.scss';
import { useHomepageContext } from '~/homepage/Contexts/Homepage.context';
import { Heroes } from '~/components/hero/Components/HomepageHeroesLibrary';

export const HomepageHero = () => {
	const {
		homepageStore: {
			model: {
				hero
			}
		},
	} = useHomepageContext();

	const Hero = Heroes[hero];

	if (Hero === undefined) {
		return null;
	}

	return (
		<div className={styles.HeroFullWidth} data-tr-link-event-comp-name={hero} data-tr-link-event-comp-type="hero">
			<Hero />
		</div>
	);
};
