import React from 'react';

import { Hero } from '~/components/hero/Hero';
import { HORIZONTAL_ALIGNMENT, IHeroWithBackgroundImage, VERTICAL_ALIGNMENT } from '~/components/hero/Hero.type';

export const DINING = () => {
	const heroArgs: IHeroWithBackgroundImage = {
		buttonText: 'Shop Dining & Kitchen',
		buttonLink: '/catalog/dining-and-kitchen',
		headline: 'Time to Gather',
		headlineClass: '',
		headlineButtonAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		headlineButtonContainerHorizontalAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		imgHorizontalAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		imgSrc: 'homepageHero_dining',
		imgHeight: 500,
		imgWidth: 500,
		headlineButtonContainerVerticalAlignment: VERTICAL_ALIGNMENT.TOP,
		nudgeHorizontal: 0,
		nudgeVertical: 12,
		imgNudgeHorizontalXS: -330,
	};

	return (
		<Hero { ...heroArgs } />
	);
};
