import { ReactNode } from 'react';

import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';

export interface IHeroPropsCommon {
	buttonColor?: ButtonColor,
	buttonLink?: string,
	buttonText?: string,
	buttonVariant?: ButtonVariant,
	children?: ReactNode,
	headline?: string,
	headlineClass?: string,
	headlineButtonAlignment?: HORIZONTAL_ALIGNMENT,
	headlineButtonContainerHorizontalAlignment?: HORIZONTAL_ALIGNMENT,
	headlineButtonContainerVerticalAlignment?: VERTICAL_ALIGNMENT,
	nudgeHorizontal?: number,
	nudgeVertical?: number,
	caption?: string,
}

export interface IHeroWithBackgroundImage extends IHeroPropsCommon {
    imgSrc: string,
	imgHeight: number,
	imgWidth: number,
	imgHorizontalAlignment?: HORIZONTAL_ALIGNMENT,
	imgNudgeHorizontalXS?: number,
}

export interface IHeroWithBackgroundColor extends IHeroPropsCommon {
	backgroundColor: string,
	backgroundHeight?: number,
	backgroundHeightSm?: number,
}

interface IHeroVideoSettings {
	thumbnailHeight: number;
	thumbnailImage: string;
	thumbnailPriority: boolean;
	thumbnailWidth: number;
	videoId: string;
}
export interface IHeroWithVideo extends IHeroPropsCommon {
	desktopVideoSettings: IHeroVideoSettings,
	mobileVideoSettings: IHeroVideoSettings,
}

export enum HORIZONTAL_ALIGNMENT {
	CENTER = 'CENTER',
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
}

export enum VERTICAL_ALIGNMENT {
	BOTTOM = 'BOTTOM',
	CENTER = 'CENTER',
	TOP = 'TOP',
}

export const hasBackgroundColor = (
	data: IHeroPropsCommon | IHeroWithBackgroundColor | IHeroWithBackgroundImage,
): data is IHeroWithBackgroundColor => {
	return Object.prototype.hasOwnProperty.call(data, 'backgroundColor');
};

export const hasBackgroundImage = (
	data: IHeroPropsCommon | IHeroWithBackgroundColor | IHeroWithBackgroundImage,
): data is IHeroWithBackgroundImage => {
	return Object.prototype.hasOwnProperty.call(data, 'imgSrc');
};
