export const wistiaVideoMediaType = 'VIDEO_WISTIA';

export enum ThumbnailSize {
	Default = 'default',
	Showcase = 'showcase',
}

export const thumbnailSizeOptions: Record<ThumbnailSize, string> = {
	[ThumbnailSize.Default]: '65x45',
	[ThumbnailSize.Showcase]: '640x360',
};

export enum ShowcaseVariant {
	Careers = 'careers',
	Standard = 'standard',
}

export enum WistiaVideoId {
	UrbanWoodDesktop = 'wq4i361hq1',
	UrbanWoodMobile = 'a2o5kcco9d',
	UrbanWoodDesktop15 = 'nh1lr6uag9',
	UrbanWoodMobile15 = '8e9c0hywo4',
	GoodFurnitureDesktop = 'wwu0h7k6th',
	GoodFurnitureMobile = 'wivukxics3',
}
