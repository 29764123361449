export interface IShoppingCarousel {
	headline: string,
	links: IShoppingCarouselLink[];
	panelsPerView: number;
	trLinkEventCompType?: string;
}

export interface IShoppingCarouselLink {
	caption: string,
	href: string,
	imageHeight: number,
	imageSrc: string,
	imageWidth: number,
}

export enum SHOPPING_CAROUSELS {
    FRESH_FALL_24 = 'FRESH_FALL_24',
	NOV_NEW_24 = 'NOV_NEW_24',
}
