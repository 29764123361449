import React, { useState } from 'react';
import classNames from 'classnames';

import { WistiaPlayerStore } from '~/components/wistia-player/Stores/WistiaPlayer.store';
import { WistiaPlayerThumbnail } from '~/components/wistia-player/Components/WistiaPlayerThumbnail';
import { WistiaPlayButton } from '~/components/wistia-player/Components/WistiaPlayButton';
import { WistiaPlayer } from '~/components/wistia-player/Components/WistiaPlayer';

interface IProps {
	store?: WistiaPlayerStore;
	thumbnailHeight: number;
	thumbnailImage: string;
	thumbnailPriority: boolean;
	thumbnailWidth: number;
	videoId: string;
}

export const WistiaPlayerWithThumbnail = (props: IProps) => {
	const {
		store = undefined, thumbnailImage = null, thumbnailWidth = 960, thumbnailHeight = 960, thumbnailPriority = false, videoId = '',
	} = props;

	if (!thumbnailImage) {
		return null;
	}

	const [loadVideo, setLoadVideo] = useState<boolean>(false);
	const [showThumbnail, setShowThumbnail] = useState<boolean>(true);

	const playVideo = () => {
		setLoadVideo(true);
	};

	const hideThumbnail = () => {
		setTimeout(() => {
			setShowThumbnail(false);
		}, 500);
	};

	return (
		<div
			className="tw-relative"
		>
			<div data-qa={'wistia-thumbnail'} className={classNames('tw-w-full tw-flex tw-justify-center tw-items-center tw-relative', {
				'tw-hidden': !showThumbnail,
			})}>
				<WistiaPlayButton clickHandler={playVideo} />
				<WistiaPlayerThumbnail
					clickHandler={playVideo}
					height={thumbnailHeight}
					priority={thumbnailPriority}
					url={thumbnailImage}
					videoId={videoId}
					width={thumbnailWidth}
				/>
			</div>
			{loadVideo &&
				<WistiaPlayer className={classNames({
					'tw-absolute tw-top-0 tw-z-20': showThumbnail,
				})} videoId={videoId} store={store} shouldAutoPlay={true} onPlay={hideThumbnail} />
			}
		</div>
	);
};
