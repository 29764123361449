import React, { MouseEventHandler } from 'react';

import { Link } from '~/components/Buttons/Components/Link';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';

import { MagicHeaderTag } from '~/components/magic-header';
import { noop } from '~/util/noop';

export const ParamusStorePromo = ({ clickHandler = noop }: { clickHandler?: MouseEventHandler }) => {
	return (
		<div className="tw-bg-[#4F676B] tw-flex tw-justify-center tw-text-center tw-px-6 tw-py-10">
			<div className="tw-flex tw-flex-col tw-items-center tw-space-y-[15px] md:tw-space-y-[20px]">
				<MagicHeaderTag headerLevel={2} className="tw-heading-3 md:tw-heading-1 tw-font-normal tw-text-white">Now Open in New Jersey!</MagicHeaderTag>
				<span className="tw-body-2 tw-text-white">Visit our new store in the Paramus Design Center.</span>
				<Link
					className=""
					color={ButtonColor.White}
					href="/stores/paramus"
					isTransparent
					variant={ButtonVariant.Hollow}
					onClick={clickHandler}
				>
					See Paramus Store Details
				</Link>
			</div>
		</div>
	);
};
