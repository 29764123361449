import React from 'react';

import styles from '~/homepage/Components/HomepageFeatCats.module.scss';
import { useHomepageContext } from '~/homepage/Contexts/Homepage.context';
import { FeatCatsLibrary } from '~/components/featured-categories/Components/HomepageFeatCatsLibrary';

export const HomepageFeatCats = () => {
	const {
		homepageStore: {
			model: {
				featCats
			}
		},
	} = useHomepageContext();

	const FeatCats = FeatCatsLibrary[featCats];

	if (FeatCats === undefined) {
		return null;
	}

	return (
		<div className={styles.FeatCats}>
			<FeatCats />
		</div>
	);
};
