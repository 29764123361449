import React, { useEffect } from 'react';

import { IWistiaQueue, IWistiaVideoMedia } from '~/components/wistia-player/Types/WistiaPlayer.type';
import { S7Image } from '~/components/Images/S7Image';

import styles from '~/components/wistia-player/Components/WistiaPlayer.module.scss';
import { noop } from '~/util/noop';

declare const window: {
	_wq?: IWistiaQueue[];
} & Window;

interface IProps {
	altText?: string;
	clickHandler?: () => void;
	duration?: string;
	height?: number;
	maxWidth?: number;
	priority?: boolean;
	url: string;
	videoId: string;
	width?: number;
	onHasData?: (media: IWistiaVideoMedia) => void;
}

export const WistiaPlayerThumbnail = (props: IProps) => {
	const {
		altText = '',
		clickHandler = noop,
		duration = '',
		height = 960,
		priority = false,
		width = 960,
	} = props;

	useEffect(() => {
		window._wq = window._wq || [];

		window._wq.push({
			id: props.videoId,
			onHasData: (video) => {
				if (!video) {
					return;
				}

				if (props.onHasData) {
					props.onHasData(video.data.media);
				}
			},
		});
	}, []);

	return (
		<div
			className={styles.thumbnail}
			style={{ maxWidth: props.maxWidth }}
		>
			<S7Image
				alt={altText}
				className="tw-w-full"
				height={height}
				onClick={() => clickHandler()}
				priority={priority}
				src={props.url}
				width={width}
			/>
			{duration && (
				<span className={styles.thumbnailDuration}>
					<span className={'tw-sr-only'}>Video item duration</span>{duration}
				</span>
			)}
		</div>
	);
};
