import React from 'react';

import { FeaturedCategories } from '~/components/featured-categories/FeaturedCategories';
import { FeaturedCategoriesModel } from '~/components/featured-categories/Models/FeaturedCategories.model';

export const OUTDOOR_LIVING = () => {
	const model = new FeaturedCategoriesModel({
		featuredCategories: {
			categories: [
				{
					imgSrc: 'outdoor_living_01',
					imgDimensions: [2528, 1580],
					link: '/catalog/outdoor',
					text: 'Outdoor',
				},
				{
					imgSrc: 'outdoor_living_02',
					imgDimensions: [1248, 1580],
					link: '/catalog/living',
					text: 'Living',
					imgNudgeVerticalXS: -125,
				},
			],
			headerLevel: 'h2',
		}
	});

	return (
		<FeaturedCategories model={model} />
	);
};
