import Flicking from '@egjs/react-flicking';
import React, { forwardRef } from 'react';

import type { FlickingProps, ReadyEvent } from '@egjs/react-flicking';
import type { ForwardedRef, RefObject } from 'react';

import { Slider } from '~/components/Slider/Slider';
import { flickingMethod } from '~/components/Slider/Slider.utils';
import { SliderDot } from '~/components/Slider/SliderDot';

import styles from '~/components/Slider/SliderDots.module.scss';

export const SliderDots = forwardRef(({
	onClick,
	panelCount,
	sliderDotClassName,
	sliderProps,
	sliderProps: {
		defaultIndex = 0,
		panelsPerView = 0,
	} = {},
}: {
	onClick?: ({ index }: { index: number }) => void,
	sliderDotClassName?: string,
	panelCount: number,
	sliderProps?: Partial<FlickingProps>,
}, ref: ForwardedRef<Flicking>) => {
	const panelsPerViewToUse = panelCount < panelsPerView ? panelCount : panelsPerView;

	async function handleReady(readyEvent: ReadyEvent<Flicking>) {
		sliderProps?.onReady?.(readyEvent);

		await flickingMethod(async () => {
			await (ref as RefObject<Flicking>).current?.moveTo(defaultIndex);
		});
	}

	return (
		<Slider
			{...sliderProps}
			bound
			className={styles['slider-dots']}
			data-qa="slider-dots"
			disableOnInit
			onReady={handleReady}
			panelsPerView={panelsPerViewToUse}
			ref={ref}
		>
			{
				[...Array(panelCount).keys()].map((key) => {
					return (
						<div
							className={styles['slider-dot-container']}
							key={`slider-dot-${key}`}
						>
							<SliderDot
								dotClassName={sliderDotClassName}
								flickingRef={ref as RefObject<Flicking>}
								index={key}
								onClick={onClick}
								panelCount={panelCount}
							/>
						</div>
					);
				})
			}
		</Slider>
	);
});
