import {
	idProp,
	Model,
	model, modelAction, prop,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';
import { IFeaturedCategories } from '~/components/featured-categories/FeaturedCategories.type';

@model(`${modelNamespace.COMPONENT}/FeaturedCategories`)
export class FeaturedCategoriesModel extends Model({
	id: idProp,
	featuredCategories: prop<IFeaturedCategories>(),
	largestImageIndex: prop<number>(0).withSetter(),
	smallestImageIndex: prop<number>(0).withSetter(),
}) {
	get flexGrowValue() {
		return true;
	}

	get categories() {
		return this.featuredCategories.categories;
	}

	@modelAction
	orderTheImagesBySize() {
		let largestImage = 0;

		this.featuredCategories.categories.forEach(({ imgDimensions }: { imgDimensions: number[] }, index: number) => {
			const imageWidth = imgDimensions[0] * imgDimensions[1];

			if (imageWidth > largestImage) {
				this.setLargestImageIndex(index);
				largestImage = imageWidth;
			} else {
				this.setSmallestImageIndex(index);
			}
		});

		return this.largestImageIndex;
	}

	@modelAction
	calcImageProportions() {
		const flexGrow = parseFloat((this.categories[this.smallestImageIndex].imgDimensions[0] / this.categories[this.largestImageIndex].imgDimensions[0]).toFixed(2));

		Object.assign(this.categories[this.largestImageIndex], {
			viewProportion: 100,
		});
		Object.assign(this.categories[this.smallestImageIndex], {
			viewProportion: flexGrow,
		});
	}

	onInit() {
		this.orderTheImagesBySize();
		this.calcImageProportions();
	}
}
