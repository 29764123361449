import React from 'react';

import { Button } from '~/components/Buttons/Components/Button';
import { noop } from '~/util/noop';

import styles from '~/components/wistia-player/Components/WistiaPlayer.module.scss';

interface IProps {
	clickHandler: () => void;
}

export const WistiaPlayButton = (props: IProps) => {
	const {
		clickHandler = noop,
	} = props;

	return (
		<div className={styles.playButton} data-qa="wistia-play-btn">
			<Button onClick={clickHandler}>
				<div className={styles.triangle}></div>
				<span className="tw-sr-only">Play Video</span>
			</Button>
		</div>
	);
};
