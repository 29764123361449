import { s7ContentPath } from '~/global/global.constants';
import { addToUrl } from '~/util/addToUrl';
import { formatCurrency } from '~/util/formatCurrency';

export class RecoItemModel {
	finalPrice

	name

	imageHeight = 85

	imagePreset = '$150w$'

	imageWidth = 160

	imageUrl

	index

	leadtimeText

	price

	priceRanges

	promotionFlag

	ratingCount

	sku

	url

	reviewRating

	constructor(data, index, useSkuImage = false) {
		this.finalPrice = parseFloat(data.final_price);
		this.name = data.name;
		this.imageUrl = useSkuImage && data.sku_image_url ? data.sku_image_url : data.image_url;
		this.index = index;
		this.leadtimeText = data.leadtime_text;
		this.price = parseFloat(data.price);
		this.priceRanges = data.price_ranges;
		this.ratingCount = data.rating_count;
		this.promotionFlag = data.promotion_flag || '';
		this.reviewRating = parseFloat(data.review_rating);
		this.sku = data.sku;
		this.url = useSkuImage && data.sku_url ? data.sku_url : data.url;
	}

	get formattedFinalPrice() {
		if (Number.isNaN(this.finalPrice)) {
			return '';
		}
		return formatCurrency(this.finalPrice, true);
	}

	get formattedPrice() {
		if (Number.isNaN(this.price)) {
			return '';
		}
		return formatCurrency(this.price, true);
	}

	get imageSrc() {
		return addToUrl(this.imageUrl, `wid=${this.imageWidth}&hei=${this.imageHeight}&${this.imagePreset}`);
	}

	get imageSrcSet() {
		return `${addToUrl(this.imageUrl, `wid=${this.imageWidth * 2}&hei=${this.imageHeight * 2}&${this.imagePreset}`)} 2x`;
	}

	get isInvalid() {
		return Boolean(!this.url || !this.imageUrl || !this.name);
	}

	get ratingImageSrc() {
		if (Number.isNaN(this.reviewRating)) {
			return '';
		}
		const itemRatingImage = this.reviewRating.toString().replace('.', '_');

		return `${s7ContentPath}/review-stars-${itemRatingImage}`;
	}

	get isClearanceItem() {
		return this.promotionFlag.toLowerCase() === 'clearance';
	}

	get showClearancePrice() {
		return this.isClearanceItem;
	}

	get showRating() {
		return !Number.isNaN(this.reviewRating) && !Number.isNaN(this.ratingCount);
	}
}
