import type { HomepageModel } from '~/homepage/Models/Homepage.model';

export class HomepageStore {
	model;

	constructor(model: HomepageModel) {
		this.model = model;
	}
}

export const HomepageStoreFactory = {
	create: (model: HomepageModel) => {
		const homepageStore = new HomepageStore(model);

		return homepageStore;
	},
};
