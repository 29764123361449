'use client';

import React, { useState } from 'react';
import { ReadonlyURLSearchParams } from 'next/navigation';

import { MagicTabccordionContainer } from '~/components/magic-tabccordion/Components/Containers/MagicTabccordionContainer';
import { MagicTabccordionStore } from '~/components/magic-tabccordion/Stores/magicTabccordion.store';
import { TabbedShoppingTab } from '~/components/tabbed-shopping/tabbed-shopping-tab/TabbedShoppingTab';
import { ITabbedShoppingTabsData, TabbedShoppingTabList } from '~/components/tabbed-shopping/TabbedShopping.type';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import styles from '~/components/tabbed-shopping/TabbedShopping.module.scss';

interface IProps {
	headline: string;
	tabList: TabbedShoppingTabList;
	query?: ReadonlyURLSearchParams | null;
}

export const TabbedShopping = (props: IProps) => {
	const tabsData: ITabbedShoppingTabsData = {
		id: 'tabbedShopping',
		renderMode: 'TABS',
		tabGroups: [{ tabs: props.tabList }],
		tabSettings: {
			tabTitleClass: styles.tabTitle,
		},
		setTabUrlParams: false,
		wrapperRenderer: TabbedShoppingTab,
	};
	const { linkEventStore } = useGlobalContext();

	const { query = undefined } = props;

	//@ts-ignore
	const [store] = useState(() => new MagicTabccordionStore(tabsData, linkEventStore, true, query));

	return (
		<div className={styles.root}>
			<h2 className={styles.headline}>{props.headline}</h2>
			<MagicTabccordionContainer
				className={'no-border'}
				store={store}
			/>
		</div>
	);
};
