import React from 'react';

import { IFeaturedCategory } from '~/components/featured-categories/FeaturedCategories.type';
import { s7ImagePath } from '~/global/global.constants';

import styles from '~/components/featured-categories/FeaturedCategories.module.scss';
import { MagicHeaderTag } from '~/components/magic-header';
import { S7Image } from '~/components/Images/S7Image';
import { FeaturedCategoriesModel } from '~/components/featured-categories/Models/FeaturedCategories.model';

export const FeaturedCategories = ({ model }: { model: FeaturedCategoriesModel }) => {
	const {
		featuredCategories: {
			headerLevel = 'h3',
			categories = [],
		} = {},
	} = model;

	return (
		<div data-qa="featured-categories" className={styles.featuredCategoryContainer}>
			{ categories.map((category: IFeaturedCategory, index) => {
				const {
					imgDimensions = [],
					imgNudgeVerticalXS = 0,
					imgSrc,
					link = '',
					text = '',
					viewProportion = 50,
				} = category;

				const [width, height] = imgDimensions;
				const categoriesQty = categories.length;

				return (
					<div data-qa="featured-category" key={`featured-category-${index}`}>
						<a
							className={styles.featuredCategoryLink}
							href={link}
							data-tr-link-event-comp-type="featured categories"
							data-tr-link-event-comp-name={text}
							data-tr-link-event-comp-position={`${index + 1}:${categoriesQty}`}>
							<div data-qa="featured-category-img" className={styles.featuredCategoryImgContainer}
								//@ts-ignore
								style={{ '--imgNudgeVerticalXS': `${imgNudgeVerticalXS}px` }}>
								<S7Image
									alt=""
									src={`${s7ImagePath}/${imgSrc}`}
									width={width}
									height={height}
									priority={false}
									sizes={`(max-width: 440px) 100vw, (min-width: 441px) ${viewProportion}vw`}
								/>
							</div>
							<MagicHeaderTag headerLevel={headerLevel} className={`${styles.featuredCategoryTitle} tw-body-1`}>{text}</MagicHeaderTag>
						</a>
					</div>
				);
			})}
		</div>
	);
};
