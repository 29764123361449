import React, { MouseEventHandler } from 'react';

import { Link } from '~/components/Buttons/Components/Link';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import { MagicHeaderTag } from '~/components/magic-header';

export const NovClearancePromo = ({ clickHandler }: { clickHandler?: MouseEventHandler }) => {
	return (
		<>
			<div className="tw-bg-[#A63535] tw-flex tw-justify-center tw-text-center tw-py-12 tw-mb-2">
				<div className="tw-flex tw-flex-col tw-items-center tw-relative tw-w-full">
					<MagicHeaderTag headerLevel={2} className="tw-w-full md:tw-text-[80px] tw-text-[50px] tw-leading-[0.65em] tw-font-semibold tw-uppercase tw-text-white tw-border-b tw-border-white tw-mb-5">Clearance</MagicHeaderTag>
					<p className="tw-body-2 tw-font-semibold tw-text-white tw-mb-4 tw-px-10">100s of new items added! Get 20-50% off discontinued furniture and decor.</p>
					<Link
						onClick={clickHandler}
						className=""
						color={ButtonColor.White}
						href="/clearance"
						isTransparent
						variant={ButtonVariant.Hollow}
					>
					Shop Clearance
					</Link>
				</div>
			</div>
		</>
	);
};
