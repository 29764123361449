import React, { ComponentProps } from 'react';
import dynamic from 'next/dynamic';

import { WistiaPlayerStore } from '~/components/wistia-player/Stores/WistiaPlayer.store';
import { WistiaPlayer } from '~/components/wistia-player/Components/WistiaPlayer';

export const WistiaPlayerDynamic = dynamic<ComponentProps<typeof WistiaPlayer>>(
	() => import('~/components/wistia-player/Components/WistiaPlayer').then(module => module.WistiaPlayer),
	{ ssr: false }
);

export const openVideoInModal = (store: WistiaPlayerStore) => {
	const { isMediaQueryMd } = store;

	store.magicModal?.openModal({
		content: {
			children: (
				<WistiaPlayerDynamic
					shouldAutoPlay={isMediaQueryMd}
					store={store}
					videoId={store.videoId}
				/>
			)
		},
		id: 'wistiaVideo',
		title: store.title,
		keepScrollPosition: true,
	});
};
