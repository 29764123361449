import Flicking from '@egjs/react-flicking';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import type { RefObject } from 'react';
import type {
	AfterResizeEvent, ChangedEvent, ReadyEvent, ReachEdgeEvent
} from '@egjs/react-flicking';

import { Button } from '~/components/Buttons/Components/Button';
import { ButtonVariant, ArrowButtonDirection, ArrowButtonSize } from '~/components/Buttons/Types/constants';
import { DIRECTION, EVENTS } from '~/components/Slider/Slider.constants';
import { flickingMethod } from '~/components/Slider/Slider.utils';

import type { ButtonProps } from '~/components/Buttons/Types/constants';

export const SliderArrow = ({
	buttonProps,
	direction,
	flickingRef,
	onClick,
	size,
}: {
	buttonProps?: ButtonProps,
	direction: keyof typeof DIRECTION,
	flickingRef: RefObject<Flicking>,
	onClick?: ({ direction, index }: { direction: keyof typeof DIRECTION, index: number }) => void,
	size?: ArrowButtonSize,
}) => {
	const [disabled, setDisabled] = useState(true);

	const [showControls, setShowControls] = useState(false);

	async function handleClick() {
		const circular = flickingRef.current?.circular;

		const index = flickingRef.current?.index || 0;

		const panelCount = flickingRef.current?.panelCount || 0;

		const panelsPerView = flickingRef.current?.panelsPerView || 0;

		const reachedEnd = index === panelCount - 1;

		const reachedEndAdjustment = panelsPerView - (panelCount % panelsPerView);

		const moveToNextIndex = index + panelsPerView;

		const moveToPrevIndex = index - (reachedEnd ? panelsPerView + reachedEndAdjustment : panelsPerView);

		if (direction === DIRECTION.NEXT) {
			await flickingMethod(async () => {
				if (circular) {
					await flickingRef.current?.next();
				} else {
					await flickingRef.current?.moveTo(moveToNextIndex < panelCount - 1 ? moveToNextIndex : panelCount - 1);
				}
			});
		}

		if (direction === DIRECTION.PREV) {
			await flickingMethod(async () => {
				if (circular) {
					await flickingRef.current?.prev();
				} else {
					await flickingRef.current?.moveTo(moveToPrevIndex >= 0 ? moveToPrevIndex : 0);
				}
			});
		}

		onClick?.({
			direction,
			index: flickingRef.current?.index || 0,
		});
	}

	function updateDisabled({
		index = 0,
		panelCount = 0,
	}) {
		const reachedEdge = (direction === DIRECTION.NEXT && index === panelCount - 1) || (direction === DIRECTION.PREV && index === 0);

		setDisabled(flickingRef.current?.circular ? false : reachedEdge);
	}

	function updateShowControls({
		panelCount = 0,
		panelsPerView = 0,
	}) {
		const isOverflow = panelCount > panelsPerView;

		setShowControls(isOverflow);
	}

	function handleAfterResize({
		currentTarget: {
			panelCount,
			panelsPerView,
		},
	}: AfterResizeEvent) {
		updateShowControls({
			panelCount,
			panelsPerView,
		});
	}

	function handleChanged({
		currentTarget: {
			index,
			panelCount,
		},
	}: ChangedEvent) {
		updateDisabled({
			index,
			panelCount,
		});
	}

	function handleReachEdge({ direction: reachEdgeDirection }: ReachEdgeEvent) {
		if (direction === reachEdgeDirection && !flickingRef.current?.circular) {
			setTimeout(() => {
				setDisabled(true);
			}, 1000);
		}
	}

	function handleReady({
		currentTarget: {
			index,
			panelCount,
			panelsPerView,
		},
	}: ReadyEvent) {
		updateDisabled({
			index,
			panelCount,
		});

		updateShowControls({
			panelCount,
			panelsPerView,
		});
	}

	useEffect(() => {
		flickingRef.current?.on?.(EVENTS.AFTER_RESIZE, handleAfterResize);

		flickingRef.current?.on?.(EVENTS.CHANGED, handleChanged);

		flickingRef.current?.on?.(EVENTS.REACH_EDGE, handleReachEdge);

		flickingRef.current?.on?.(EVENTS.READY, handleReady);

		return () => {
			flickingRef.current?.off?.(EVENTS.AFTER_RESIZE, handleAfterResize);

			flickingRef.current?.off?.(EVENTS.CHANGED, handleChanged);

			flickingRef.current?.off?.(EVENTS.REACH_EDGE, handleReachEdge);

			flickingRef.current?.off?.(EVENTS.READY, handleReady);
		};
	}, [flickingRef.current]);

	return (
		<Button
			{...buttonProps}
			className={classNames({ 'tw-hidden': !showControls })}
			data-qa={`slider-arrow-${direction}`}
			data-tr-link-event-track={false}
			direction={direction === DIRECTION.NEXT ? ArrowButtonDirection.Right : ArrowButtonDirection.Left}
			disabled={disabled}
			onClick={handleClick}
			size={size || ArrowButtonSize.Medium}
			variant={ButtonVariant.Arrow}
		>
			<span className="tw-sr-only">
				Navigate to {direction === DIRECTION.NEXT ? 'next' : 'previous'} item
			</span>
		</Button>
	);
};
