import React, { type ElementType } from 'react';
import cn from 'classnames';

type Props = {
	as?: ElementType
	classNames?: string,
	containerBorder?: 'TOP' | 'BOTTOM',
	children?: React.ReactNode
	'data-qa'?: string
};

export const DivideYWrapper = (props: Props) => {
	const {
		as: TagName = 'div', classNames = '', children, containerBorder,
	} = props;
	const qa = props['data-qa'] || 'divide-y-wrapper';
	const hasChildren = Boolean(children && React.Children.toArray(children).filter(Boolean).length);

	if (!hasChildren) {
		return null;
	}

	return (
		<TagName
			data-qa={qa}
			className={cn('tw-flex tw-flex-col tw-divide-y tw-divide-gray-400', {
				[classNames]: Boolean(classNames),
				'tw-border-t tw-border-gray-400': containerBorder === 'TOP' && hasChildren,
				'tw-border-b tw-border-gray-400': containerBorder === 'BOTTOM' && hasChildren,
			})}
		>
			{ children }
		</TagName>
	);
};
