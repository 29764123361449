import { PROMOTIONALFLEX_ID } from '~/homepage/Components/PromotionalFlex/PromotionalFlex.type';
import { ClearancePromo } from '~/components/promotional-flex/Components/ClearancePromo';
import { ELYPromo } from '~/components/promotional-flex/Components/ELYPromo';
import { FallOpenHousePromo } from '~/components/promotional-flex/Components/FallOpenHousePromo';
import { FireclayTilePromo } from '~/components/promotional-flex/Components/FireclayTilePromo';
import { MATaxFreePromo } from '~/components/promotional-flex/Components/MATaxFreePromo';
import { MmsSweepsPromo } from '~/components/promotional-flex/Components/MmsSweepsPromo';
import { NovClearancePromo } from '~/components/promotional-flex/Components/NovClearancePromo';
import { ParamusStorePromo } from '~/components/promotional-flex/Components/ParamusStorePromo';

export const PromotionalFlexs: Record<PROMOTIONALFLEX_ID, any> = {
	'ParamusStorePromoFlex': ParamusStorePromo,
	'ClearancePromoFlex': ClearancePromo,
	'ELYPromoFlex': ELYPromo,
	'FallOpenHousePromoFlex': FallOpenHousePromo,
	'FireclayTilePromoFlex': FireclayTilePromo,
	'MATaxFreePromoFlex': MATaxFreePromo,
	'MmsSweepsPromoFlex': MmsSweepsPromo,
	'NovClearancePromoFlex': NovClearancePromo,
};

export function isPromotionalFlexID(key: any): key is PROMOTIONALFLEX_ID {
	return key in PromotionalFlexs;
}
