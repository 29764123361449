import React from 'react';

import { MagicHeaderTag } from '~/components/magic-header';

export const MATaxFreePromo = () => {
	return (
		<>
			<div className="tw-bg-[#234143] tw-flex tw-justify-center tw-text-center tw-px-6 tw-py-10 tw-mb-1">
				<div className="tw-flex tw-flex-col tw-items-center tw-space-y-[15px]">
					<MagicHeaderTag headerLevel={2} className="tw-heading-3 md:tw-heading-1 tw-font-normal tw-text-white">Massachusetts Tax-free Weekend</MagicHeaderTag>
					<span className="tw-body-2 tw-font-semibold tw-text-white">Saturday, August 10 – Sunday, August 11 </span>
					<p className="tw-body-2 tw-text-white">Save items to your Cart now and buy online or in store during the event. Any item that’s $2,500 or less qualifies*.</p>
				</div>
			</div>
			<p className="tw-text-gray-300 md:tw-font-size-2xs tw-font-size-xs md:tw-text-center">*Only new orders are eligible, and the state of Massachusetts requires full payment at the time of purchase to qualify for tax-free status.</p>
		</>
	);
};
