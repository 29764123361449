import React from 'react';

import { FeaturedCategories } from '~/components/featured-categories/FeaturedCategories';
import { FeaturedCategoriesModel } from '~/components/featured-categories/Models/FeaturedCategories.model';

export const DINING_LIVING = () => {
	const model = new FeaturedCategoriesModel({
		featuredCategories: {
			categories: [
				{
					imgSrc: 'dining_living_01',
					imgDimensions: [2528, 1580],
					link: '/catalog/dining-and-kitchen',
					text: 'Dining & Kitchen',
				},
				{
					imgSrc: 'dining_living_02',
					imgDimensions: [1248, 1580],
					link: '/catalog/living',
					text: 'Living',
					imgNudgeVerticalXS: -125,
				},
			],
			headerLevel: 'h2',
		}
	});

	return (
		<FeaturedCategories model={model} />
	);
};
