'use client';

import React, { createContext, useContext } from 'react';
import { useSearchParams } from 'next/navigation';

import { HomepageModel } from '~/homepage/Models/Homepage.model';
import { HomepageStoreFactory, HomepageStore } from '~/homepage/Stores/Homepage.store';
import { HomepageProps } from '~/pages';
import { HomepageSettings } from '~/homepage/HomepageSettings';

interface HPContext { homepageStore: HomepageStore }

const HomepageContext = createContext<HPContext>({} as HPContext);

interface PageProps {
	props?: HomepageProps,
	children: React.ReactNode,
}

export const HomepageContextProvider = (pageProps: PageProps) => {
	const {
		children = null,
	} = pageProps;

	const queryParams = useSearchParams();

	const model = new HomepageModel(HomepageSettings, queryParams);
	const homepageStore = HomepageStoreFactory.create(model);

	const store: HPContext = { homepageStore };

	return <HomepageContext.Provider value={store}>{children}</HomepageContext.Provider>;
};

export const useHomepageContext = () => useContext(HomepageContext);
