import React, { MouseEventHandler } from 'react';

import { Link } from '~/components/Buttons/Components/Link';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';

import { MagicHeaderTag } from '~/components/magic-header';
import { noop } from '~/util/noop';

export const FireclayTilePromo = ({ clickHandler = noop }: { clickHandler?: MouseEventHandler }) => {
	return (
		<div className="tw-bg-[#4A6695] tw-flex tw-justify-center tw-text-center tw-px-6 tw-py-10">
			<div className="tw-flex tw-flex-col tw-items-center tw-space-y-[15px] md:tw-space-y-[20px]">
				<MagicHeaderTag headerLevel={2} className="tw-heading-3 md:tw-heading-1 tw-font-normal tw-text-white">Fireclay Tile Now Open at Select Stores</MagicHeaderTag>
				<p className="tw-body-2 tw-text-white md:tw-w-3/4">Visit Fireclay Tile, our shop-in-shop partner and fellow B Corp™, during our normal store hours to connect with their team and see samples of their hand-crafted, sustainably made tiles.</p>
				<Link
					className=""
					color={ButtonColor.White}
					href="/stores/edina"
					isTransparent
					variant={ButtonVariant.Hollow}
					onClick={clickHandler}
				>
					See Edina Store Details
				</Link>
				<Link
					className=""
					color={ButtonColor.White}
					href="/stores/washington-dc"
					isTransparent
					variant={ButtonVariant.Hollow}
					onClick={clickHandler}
				>
					See Washington, D.C. Store Details
				</Link>
			</div>
		</div>
	);
};
