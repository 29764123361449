import React, { MouseEventHandler } from 'react';

import { TextLink } from '~/components/Buttons/Components/TextLink';
import { Link } from '~/components/Buttons/Components/Link';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import { noop } from '~/util/noop';

export const MmsSweepsPromo = ({ clickHandler = noop }: { clickHandler?: MouseEventHandler }) => {
	return (
		<>
			<div className="tw-bg-[#3A472B] tw-flex tw-justify-center tw-text-center tw-px-6 tw-py-10 tw-mb-1">
				<div className="tw-flex tw-flex-col tw-items-center tw-space-y-[15px]">
					<h2 className="tw-heading-3 md:tw-heading-1 tw-font-normal tw-text-white">$5,000 Gift Card Sweepstakes<sup className="md:tw-heading-4 tw-heading-5 md:tw-top-[-0.9em] tw-top-[-0.8em]">†</sup></h2>
					<p className="tw-body-2 tw-text-white">Opt in to win! Now through November 11, sign up for text updates and you’ll be entered for a chance to win a $5,000 Room & Board gift card.</p>
					<Link
						className=""
						color={ButtonColor.White}
						href="https://go.roomandboard.com/2F1158"
						isTransparent
						variant={ButtonVariant.Hollow}
						onClick={clickHandler}
						target="_blank"
					>
					Enter Now
					</Link>
				</div>
			</div>
			<p className="tw-text-gray-300  tw-font-size-2xs md:tw-text-center"><sup>†</sup>Void where prohibited. No purchase necessary to enter; a purchase will not increase your chance of winning. Subject to <TextLink href="https://go.roomandboard.com/FEQ29a" target="_blank"><span className="tw-font-size-2xs">Official Rules</span></TextLink>.</p>
		</>
	);
};
