import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from '~/components/homepage-link-list/HomepageLinkList.module.scss';

interface IProps {
	numberColumns?: 3 | 4 | 5;
	scrollingSm?: boolean;
}

export const HomepageLinkList = (props: PropsWithChildren<IProps>) => {
	const {
		numberColumns = 4,
		scrollingSm = false,
	} = props;

	return (
		<ul className={
			classNames(
				styles.root,
				`md:tw-grid-cols-${numberColumns}`,
				{
					[styles.rootScrollingSm]: scrollingSm,
				}
			)
		}>
			{props.children}
		</ul>
	);
};
