import type { CustomerPhotosData } from '~/components/customer-photos/CustomerPhotos.types';

export enum CustomerPhotosRoute {
	ModernStyleGallery = '/ideas-advice/get-inspired/modern-style-gallery',
}

export enum CustomerPhotosLinkText {
	SeeMorePhotos = 'See More Photos',
}

export enum CustomerPhotosTitle {
	Roomandboard = '#roomandboard',
}

export enum CustomerPhotosSubtitle {
	StyleOurDesigns = 'We love to see how you style our designs.',
}

export const modernStyleGalleryData: CustomerPhotosData = {
	link: CustomerPhotosRoute.ModernStyleGallery,
	linkText: CustomerPhotosLinkText.SeeMorePhotos,
	headerLevel: 2,
	subtitle: CustomerPhotosSubtitle.StyleOurDesigns,
	title: CustomerPhotosTitle.Roomandboard,
};
