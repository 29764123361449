import React from 'react';
import classNames from 'classnames';

import { ITabbedShoppingTab } from '~/components/tabbed-shopping/TabbedShopping.type';
import { TabbedShoppingLink } from '~/components/tabbed-shopping/tabbed-shopping-link/TabbedShoppingLink';
import { StaticSlider } from '~/components/static-slider/StaticSlider';
import styles from '~/components/tabbed-shopping/tabbed-shopping-tab/TabbedShoppingTab.module.scss';
import { HomepageLinkList } from '~/components/homepage-link-list/HomepageLinkList';

interface IProps {
	tab: ITabbedShoppingTab & {
		isActive: boolean;
	}
}

export const TabbedShoppingTab = (props: IProps) => {
	if (!props.tab.isActive) {
		return null;
	}

	const linksLength = props.tab.links.length;

	return (
		<>
			<div className={styles.root}>
				<HomepageLinkList>
					{props.tab.links.map((link, index) => (
						<li key={link.caption}>
							<TabbedShoppingLink {...link} trLinkEventCompPosition={`${index + 1}:${linksLength}`} />
						</li>
					))}
				</HomepageLinkList>
			</div>
			<StaticSlider
				classes={classNames(styles.rootStaticSlider, 'ScrollingContainer-maxWidth--280')}
				items={props.tab.links}
				renderer={'tabbedShoppingLink'}
			/>
		</>
	);
};
