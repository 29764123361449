import React, { useEffect, useRef } from 'react';
import Script from 'next/script';
import classNames from 'classnames';

import { IWistiaQueue } from '~/components/wistia-player/Types/WistiaPlayer.type';
import { calculateVideoPercentWatched } from '~/components/wistia-player/Utils/WistiaPlayer.utils';
import { WistiaPlayerStore } from '~/components/wistia-player/Stores/WistiaPlayer.store';

interface IProps {
	className?: string;
	isFullWidth?: boolean;
	onPlay?: () => void;
	shouldAutoPlay?: boolean;
	shouldPlayInline?: boolean;
	store?: WistiaPlayerStore;
	videoId: string;
	width?: number;
}

declare const window: {
	eventDataLayer?: any[];
	_wq?: IWistiaQueue[];
} & Window;

export const WistiaPlayer = (props: IProps) => {
	const {
		className = '', isFullWidth = true, onPlay, shouldAutoPlay = false, shouldPlayInline = true,
	} = props;
	const wistiaContainerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		window._wq = window._wq || [];

		window._wq.push({
			id: props.videoId,
			options: {
				autoPlay: shouldAutoPlay,
				playsinline: shouldPlayInline
			},
			onHasData: (loadedVideo) => {
				if (!loadedVideo || !wistiaContainerRef.current) {
					return;
				}

				const aspectRatio = loadedVideo.aspect();
				const paddingTop = `${(1 / aspectRatio) * 100}%`;
				const compName = loadedVideo.data.media.name;
				let lastPercentWatched = 0;

				wistiaContainerRef.current.style.paddingTop = paddingTop;

				loadedVideo.bind('play', () => {
					onPlay?.();

					if (!props.store) {
						return false;
					}

					props.store.trackVideoPlayEvent(compName, window.eventDataLayer);

					return loadedVideo.unbind;
				});

				loadedVideo.bind('secondchange', (secondsWatched: number) => {
					const percentWatched = calculateVideoPercentWatched(secondsWatched, loadedVideo.data.media.duration);

					props.store?.trackVideoMilestoneEvent(compName, percentWatched, lastPercentWatched, window.eventDataLayer);

					lastPercentWatched = percentWatched;
				});
			},

		});
	}, []);

	return (
		<div
			style={{ maxWidth: props.width }}
			className={classNames({
				[className]: className,
				'tw-w-full': isFullWidth
			})}
			data-tr-link-event-track={false}
		>
			<div
				className={'tw-relative'}
				ref={wistiaContainerRef}
			>
				<div className={'tw-h-full tw-absolute tw-top-0 tw-w-full'}>
					<div
						className={`wistia_embed wistia_async_${props.videoId} tw-w-full tw-h-full`}
						data-qa={'wistia-video-player'}
					/>
				</div>
				<Script src={`https://fast.wistia.com/embed/medias/${props.videoId}.jsonp`} />
				<Script src={'https://fast.wistia.com/assets/external/E-v1.js'} />
			</div>
		</div>
	);
};
